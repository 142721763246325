<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <container ref="container">
            <div class="review-detail">
                <div class="header">
                    <div class="user">
                        <div class="avatar">
                            <div
                                class="inner"
                                :style="`background-image: url('${reviewDetail.avatar||$defaultAvatar}');`"
                            />
                        </div>
                        <div class="info">
                            <div class="nickname">
                                {{ reviewDetail.nickName }}
                            </div>
                            <div class="review-time">
                                {{ reviewDetail.reviewTime | moment('YYYY-MM-DD HH:mm:ss') }}
                            </div>
                        </div>
                    </div>
                    <div class="extra">
                        <Rater
                            readonly
                            class="ta-r"
                            v-model="reviewDetail.rating"
                        />
                    </div>
                </div>

                <div class="body">
                    <div
                        v-if="reviewDetail.specDesc"
                        class="spec"
                    >
                        {{ reviewDetail.specDesc }}
                    </div>
                    <div class="content">
                        {{ reviewDetail.reviewContent }}
                    </div>
                    <ImgList
                        :column="1"
                        auto-height
                        :data="reviewDetail.reviewAttachmentList"
                    />
                </div>
            </div>
        </container>
        <div class="footer">
            <div
                class="btn btn-main"
                @click="$router.go(-1)"
            >
                返回
            </div>
        </div>
    </Page>
</template>

<script>
import { Buyer } from '@/api';
import pageMixin from '@/mixin/layout/page';

export default {
    name: 'ReviewDetail',
    mixins: [pageMixin],
    data() {
        return {
            reviewDetail: {},
        };
    },
    beforeRouteEnter(to, form, next) {
        const { reviewId } = to.params;
        Buyer.Ex.GoodsReview.buyerGoodsReviewDetail({ id: reviewId }).then(json => {
            const res = json.data;
            console.log(res);
            next(vm => {
                vm.reviewDetail = res.data;
            });
        });
    },
};
</script>

<style lang="scss">
.review-detail {
    padding: $padding;
    background-color: #fff;
    > .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .user {
            flex: 1 1 1%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            > .avatar {
                > .inner {
                    width: 0.8rem;
                    height: 0.8rem;
                    background: transparent center no-repeat;
                    background-size: cover;
                    border-radius: 100vw;
                }

                margin-right: $margin-small;
            }
            > .info {
                > .review-time {
                    color: $gray6;
                    font-size: 80%;
                }
            }
        }
        > .extra {
            flex: 0 0 auto;
        }
    }
    > .body {
        padding: $padding-small 0;
        > .spec {
            display: inline-block;
            padding: $padding-small;
            border: 1px solid $color-second;
            border-radius: 10px;
            color: $color-second;
            font-size: 80%;
            line-height: 1.2;
            margin-top: $margin-small;
        }
        > .content {
            padding: $padding-small 0;
            word-break: break-all;
        }
    }
}
</style>
