<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <template v-if="!goodsIds">
            <query-box
                class="second-header"
                top=".89rem"
                placeholder="请输入商品名称"
                show-search-btn
                show-reset-btn
                v-model="keywords"
                @query="onSearch"
            />
            <CatPicker
                v-model="catId"
                class="second-header"
                expand-label-type
                :activity-type="+activityType"
                :fulfil-give-id="fulfilGiveId"
                @input="onCategoryInput"
                @nothing="onCategoryInput"
            />
        </template>
        <container
            class="category"
            ref="container"
        >
            <!--<GoodsCategory
                v-model="catId"
                :activity-type="+activityType"
                @input="onCategoryInput"
                @nothing="onCategoryInput"/>-->
            <list class="goods">
                <goods-item
                    v-for="(item) in goodsList"
                    :key="item.id"
                    :group-buy="+activityType === 20"
                    :data="item"
                />
            </list>
            <infinite-loading
                ref="infinite"
                :disabled="infiniteDisabled"
                @infinite="onInfinite"
            />
        </container>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import infiniteLoading from '@/mixin/infiniteLoading';
import { mapGetters } from 'vuex';

export default {
    name: 'GoodsList',
    mixins: [pageMixin, infiniteLoading],
    data() {
        return {
            keywords: '',
            goodsList: [],
            catId: +this.$route.params.catId || 0,
            infiniteDisabled: !+this.$route.params.catId,
            activityType: this.$route.params.activityType,
        };
    },
    computed: {
        ...mapGetters('goods', ['catMap']),
        catName() {
            const catMap = this.catMap[this.activityType] || {};
            const mapItem = catMap[this.catId];
            return mapItem ? mapItem.name : '';
        },
        goodsIds() {
            return this.$route.query.ids;
        },
        fulfilGiveId() {
            return this.$route.query.fulfilGiveId;
        },
    },
    watch: {
        goodsIds: {
            handler(ids) {
                if (!ids) return;
                this.goodsList = [];
                this.pagination = {};
                this.getListData({ ids });
            },
            immediate: true,
        },
    },
    methods: {
        getListData({ ids, catId = this.catId, currentPage, pageSize } = {}) {
            return this.$api.Buyer.Gd.Goods.goodsList({
                ids,
                fulfilGiveId: this.fulfilGiveId,
                catId,
                activityType: this.activityType,
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
                pageSize,
            }).then(json => {
                const res = json.data;
                this.goodsList = this.goodsList.concat(res.data);
                if (res.pagination && res.pagination.currentPage === 1) {
                    this.setShareData({
                        title: this.catName,
                        // desc: goods.goodsSubtitle,
                        // imgUrl: goods.mainImageUrl,
                        link: this.$route.fullPath,
                        successData: {
                            urllinkType: 2,
                            objectId: this.catId,
                        },
                    });
                }
                return Promise.resolve(json);
            });
        },
        onCategoryInput(/*val*/) {
            // if (!val) return;
            this.infiniteDisabled = true;
            this.goodsList = [];
            this.pagination = {};
            this.$nextTick(() => {
                this.infiniteDisabled = false;
            });

            this.setShareData({
                title: `点击进入【${this.catName}】查看具体商品详情！好货好价等您来哦！`,
                desc: this.$globalVar.shopData.name,
                link: `/goods/list/${this.catId}`,
                successData: {
                    urllinkType: 2,
                    objectId: this.catId,
                },
            });
        },
        onSearch() {
            this.$router.push(`/goods/search/${encodeURIComponent(this.keywords)}`);
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
