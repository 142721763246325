<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <query-box
            class="second-header"
            top=".89rem"
            placeholder="请输入商品名称"
            show-search-btn
            show-reset-btn
            v-model="keywords"
            @query="onSearch"
        />
        <container ref="container">
            <list
                v-if="goodsList && goodsList.length"
                class="goods"
            >
                <goods-item
                    v-for="(item) in goodsList"
                    :key="item.id"
                    :data="item"
                />
            </list>
            <div
                v-else
                class="pa-a tc-g6 ta-c"
            >
                请输入商品名称进行搜索
            </div>
            <infinite-loading
                ref="infinite"
                :disabled="infiniteDisabled"
                @infinite="onInfinite"
            />
        </container>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import infiniteLoading from '@/mixin/infiniteLoading';
import { mapGetters } from 'vuex';

export default {
    name: 'GoodsList',
    mixins: [pageMixin, infiniteLoading],
    data() {
        return {
            keywords: '',
            goodsList: [],
        };
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.keywords = decodeURIComponent(to.params.keyword || '');
            vm.infiniteDisabled = !vm.keywords;
        });
    },
    computed: {
        ...mapGetters('goods', ['catMap']),
        keyword() {
            return decodeURIComponent(this.$route.params.keyword || '');
        },
    },
    methods: {
        getListData({ goodsName = this.keywords, currentPage, pageSize } = {}) {
            return this.$api.Buyer.Gd.Goods.goodsList({
                goodsName,
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
                pageSize,
            }).then(json => {
                const res = json.data;
                this.goodsList = this.goodsList.concat(res.data);
                return Promise.resolve(json);
            });
        },
        onSearch() {
            this.infiniteDisabled = true;
            this.orderList = [];
            this.goodsList = [];
            this.pagination = {};
            this.$nextTick(() => {
                this.infiniteDisabled = false;
            });
        },
    },
};
</script>

<style lang="scss">
</style>
