<!-- Created by henian.xu on 2019/7/8. -->

<template>
    <Page>
        <Container ref="container">
            <classify>
                <ClassifyPanel
                    v-for="item in catList"
                    :key="item.id"
                    :label="item.name"
                >
                    <div class="category-list">
                        <Linker
                            class="cat-item"
                            v-for="cat in item.children"
                            :key="cat.id"
                            :to="`/goods/list/${activityType}/${cat.id}`"
                        >
                            <div class="inner">
                                <div
                                    class="img-obj"
                                    :style="`background-image: url('${cat.goodsCatImageUrl||$defaultImg}');`"
                                />
                                <div class="label">
                                    {{ cat.name }}
                                </div>
                            </div>
                        </Linker>
                    </div>
                </ClassifyPanel>
            </classify>
        </Container>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'PageCategory',
    mixins: [pageMixin],
    data() {
        return {};
    },
    props: {},
    computed: {
        ...mapGetters('goods', { catList_: 'catList' }),
        catList() {
            const { catList_, activityType } = this;
            if (!catList_(activityType)) {
                this.getCatList({ activityType });
                return null;
            }
            return catList_(activityType);
        },
        activityType() {
            return this.$route.params.activityType || 0;
        },
    },
    methods: {
        ...mapActions('goods', ['getCatList']),
    },
};
</script>

<style lang="scss">
.category-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    padding: $padding-small 0;
    > .cat-item {
        width: (100% / 3);
        flex: 0 0 auto;
        padding: $padding-small;
        > .inner {
            text-align: center;
            > .img-obj {
                margin: 0 auto;
                background: center no-repeat;
                background-size: cover;
                width: 1rem;
                height: 1rem;
            }
            > .label {
                @include text-line(2);
                line-height: 1.2;
                margin-top: $margin-small;
            }
        }
    }
}
</style>
