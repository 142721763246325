<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <container ref="container">
            <div class="goods-swiper">
                <ad-swiper
                    is-images-background
                    :data="imgList"
                    :delay="0"
                    :loop="false"
                />
                <div
                    v-if="+goods.goodsStatus!==3"
                    class="sold-out"
                />
            </div>

            <div class="goods-info">
                <div
                    v-if="isPresellGoods"
                    class="header"
                >
                    <!--预售-->
                    <div class="label">
                        预售商品
                    </div>
                    <div class="countdown-wrap">
                        <Countdown
                            v-model="countdown"
                            :start-timestamps="goods.beginPresellTime"
                            :end-timestamps="goods.presellEndTime"
                        />
                        <!--<div class="tc-red fs-small">预计 {{ goods.estimateShippingTime | moment('YYYY年MM月DD日') }} 24点前发货</div>-->
                    </div>
                </div>
                <div class="body">
                    <div class="label-wrap">
                        <div class="name">
                            {{ goods.name }}
                        </div>
                        <div
                            class="desc"
                            v-if="goods.desc"
                        >
                            {{ goods.desc }}
                        </div>
                    </div>
                    <div
                        class="share"
                        :class="isCollect && 'active'"
                        @click="onCollect"
                    >
                        <i
                            class="f-icon"
                            v-if="isCollect"
                        >&#xf03b;</i>
                        <i
                            class="f-icon"
                            v-else
                        >&#xf03a;</i>
                        <div class="label">
                            {{ isCollect?'取消':'' }}收藏
                        </div>
                    </div>
                    <div
                        class="share active"
                        v-if="!!shoeSize"
                    >
                        <i class="f-icon">&#xf073;</i>
                        <div class="label">
                            推荐 <span class="badge badge-main">{{ shoeSize }}</span> 码
                        </div>
                    </div>
                </div>
                <div
                    v-if="isActivityGoods"
                    class="footer"
                >
                    <div>
                        <span class="price fs-big">
                            {{ activityGoods.price | price }}
                            <span
                                v-if="activityGoods.marketPrice"
                                class="old"
                            >{{ activityGoods.marketPrice | price }}</span>
                        </span>
                        <span
                            v-if="goods.RMBPrice"
                            class="fs-small tc-g6"
                        >&nbsp;≈<span class="price-rmb">{{ goods.RMBPrice | price }}</span></span>
                        <div
                            to="/distributor/cardList"
                            v-if="$hasPower(['onlyDistributorBuy:use','distributionIncomeUpgradeMsg:use'],{'&&':true}) && !(2 & userData.type)"
                            class="rise-tips"
                        >
                            升级权益查看分销价
                        </div>
                    </div>
                    <div
                        v-if="goodsSales"
                        class="stock tc-g6"
                    >
                        销量：{{ goodsSales }}
                    </div>
                    <!--<div class="stock tc-g6">
                        库存：{{ activityGoods.showStock }}
                    </div>-->
                </div>
                <template v-else>
                    <div class="footer">
                        <div>
                            <span class="price fs-big">
                                {{ goods.price | price }}
                                <span
                                    v-if="goods.marketPrice"
                                    class="old"
                                >{{ goods.marketPrice | price }}</span>
                            </span>
                            <span
                                v-if="goods.RMBPrice"
                                class="fs-small tc-g6"
                            >&nbsp;≈<span class="price-rmb">{{ goods.RMBPrice | price }}</span></span>
                            <div
                                to="/distributor/cardList"
                                v-if="$hasPower(['onlyDistributorBuy:use','distributionIncomeUpgradeMsg:use'],{'&&':true}) && !(2 & userData.type)"
                                class="rise-tips"
                            >
                                升级权益查看分销价
                            </div>
                        </div>
                        <div
                            v-if="goodsSales"
                            class="stock tc-g6"
                        >
                            销量：{{ goodsSales }}
                        </div>
                    <!--<div class="stock tc-g6">
                        库存：{{ goods.showStock }}
                    </div>-->
                    </div>
                    <div
                        v-if="goods.discountPrice"
                        class="footer npa-t"
                    >
                        <div class="fs-more">
                            <span class="fs-little fw-bold">到手价: </span><span class="price">{{ goods.discountPrice | price }}</span>
                        </div>
                    </div>
                </template>
                <div
                    v-if="$hasPower(['goods:showSupplierName:use'])"
                    class="footer tc-g6"
                >
                    入驻商家: {{ goods.supplierName }}
                </div>
            </div>

            <!-- 分享赚/团队提成 -->
            <template v-if="isDistributor && ((!!($globalVar.shopData.distributionIncomeMode&4) || goods.commisionPrice))">
                <!--<div class="pa-a">
                    <div
                        v-if="isDistributor && !!($globalVar.shopData.distributionIncomeMode&4)"
                        class="share-earn"
                    >
                        <div class="inner">
                            &lt;!&ndash;<div>团队收益:&nbsp;</div>&ndash;&gt;
                            <div class="commission-list">
                                <div
                                    v-for="(item,index) in goods.eachLevelPriceList"
                                    :key="index"
                                    class="dp-b"
                                >
                                    {{ item.levelName }}:
                                    <span class="price">{{ item.levelIncomePrice }}</span>
                                </div>
                                <Linker
                                    v-if="!goods.eachLevelPriceList || !goods.eachLevelPriceList.length"
                                    to="/distributor/cardList"
                                >
                                    升级权益赚取佣金
                                </Linker>
                            </div>
                        </div>
                        <div class="pa-a">
                            <div
                                class="btn btn-red big radius"
                                @click="onShareEarn"
                            >
                                分享素材
                            </div>
                        </div>
                    </div>
                </div>-->
                <!--<list>
                    <list-item
                        icon="&#xf021;"
                        icon-class-name="tc-blue"
                        label="分享素材"
                        class="link"
                        @click="onShareEarn"
                    />
                </list>-->
                <div
                    ref="copyRecommend"
                    v-if="goods.commisionPrice"
                    class="share-income"
                    :data-clipboard-text="sharedData.link"
                    @click="onShareBtn"
                >
                    <div class="inner">
                        <div class="body">
                            <div class="info">
                                <div class="label">
                                    预计收益
                                </div>
                                <div class="value">
                                    <span class="price">{{ goods.commisionPrice }}</span>
                                    <span>收益比例: {{ goods.commisionRate }}%</span>
                                </div>
                            </div>
                            <div class="share-btn">
                                立即<br>分享
                            </div>
                        </div>
                        <div class="footer">
                            分享好友下单，享受返现
                        </div>
                    </div>
                </div>
            </template>

            <!--<div
                v-if="isDistributor && ((!!($globalVar.shopData.distributionIncomeMode&4) || goods.commisionPrice))"
                class="pa-a">
                <div class="share-earn">
                    <div class="inner">
                        <div class="commission-list">
                            <div v-if="goods.commisionPrice">推荐收益：<span class="price">{{ goods.commisionPrice | price }}</span></div>
                            <template v-if="isDistributor && !!($globalVar.shopData.distributionIncomeMode&4)">
                                <div
                                    v-for="(item,index) in goods.eachLevelPriceList"
                                    :key="index"
                                    class="dp-b badge badge-red">
                                    {{ item.levelName }}:
                                    <span class="price tc-w">{{ item.levelIncomePrice }}</span>
                                </div>
                                <Linker
                                    v-if="!goods.eachLevelPriceList || !goods.eachLevelPriceList.length"
                                    to="/distributor/cardList">升级权益赚取佣金
                                </Linker>
                            </template>
                        </div>
                        &lt;!&ndash;<div class="desc">分享素材</div>&ndash;&gt;
                    </div>
                    <div
                        class="btn btn-red radius"
                        @click="onShareEarn">分享素材
                    </div>
                </div>
            </div>-->

            <!-- 拼团 -->
            <List
                v-if="isActivityGoods"
                class="ma-t"
            >
                <template v-if="activityGoodsDetail.groupBuyList.length">
                    <ListItem class="bor-b">
                        <div
                            class="label"
                            slot="label"
                        >
                            主人，有 <span class="tc-red">{{ activityGoodsDetail.groupBuyList.length }}</span> 个团在等您参加哦。
                        </div>
                        <div
                            class="extra fs-small tc-g6"
                            slot="extra"
                            @click="onMoreGroup"
                        >
                            查看更多 <i class="f-icon">&#xf012;</i>
                        </div>
                    </ListItem>
                    <GroupItem
                        v-for="item in activityGoodsDetail.groupBuyList"
                        :key="item.id"
                        :goods="goods"
                        :data="item"
                    />
                </template>
                <template v-else>
                    <ListItem class="bor-b">
                        <div
                            class="label"
                            slot="label"
                        >
                            主人，大家在等您开团呢！
                        </div>
                        <div
                            v-if="isAllowOrder"
                            class="extra btn btn-main"
                            slot="extra"
                            @click="onOpenGroup"
                        >
                            去开团
                        </div>
                    </ListItem>
                </template>
            </List>

            <list
                v-if="isLogin"
                class="ma-t"
            >
                <list-item
                    class="reverse npa-t npa-b"
                    extra="送至"
                >
                    <div
                        class="label lh-normal"
                        slot="label"
                    >
                        <address-picker
                            class="nma-a"
                            v-model="address"
                            is-simple
                        />
                    </div>
                </list-item>
                <list-item
                    v-if="address.id"
                >
                    <div
                        class="label"
                        slot="label"
                    >
                        运费: <span
                            class="pa-l"
                            :class="isNaN(freight) ?'tc-red': 'price'"
                        >
                            <template
                                v-if="isNaN(freight)"
                            >{{ freight }}</template>
                            <template
                                v-else
                            >{{ freight | price }}</template>
                        </span>
                    </div>
                </list-item>
            </list>
            <FulfilGiveBox
                class="ma-t"
                :data="fulfilGiveList"
            />

            <tabs
                class="average ma-t"
                @tabChange="onTabChange"
            >
                <tab-panel
                    label="商品详情"
                    :sign="1"
                >
                    <div
                        class="pa-a bc-w"
                        style="line-height: 0;"
                    >
                        <img
                            v-for="(item,index) in goods.detailImageList"
                            :key="index"
                            v-lazy="$options.filters.imgCropping(item)"
                            width="100%"
                        >
                    </div>
                </tab-panel>
                <!--<tab-panel
                    label="评价"
                    :sign="4"
                >
                    <div
                        class="pa-a ta-c tc-g6"
                        v-if="!reviewList"
                    >
                        加载中...
                    </div>
                    <div
                        class="pa-a ta-c tc-g6"
                        v-else-if="!reviewList.length"
                    >
                        <img
                            class="img-obj"
                            style="opacity: .3;"
                            src="~@/assets/images/icon/icon01.png"
                            width="40%"
                        >
                        <div class="tc-g6">
                            暂无评价
                        </div>
                    </div>
                    <template v-else>
                        <ReviewItem
                            v-for="item in reviewList"
                            :key="item.id"
                            :data="item"
                        />
                        <div
                            v-if="reviewListTotalRows>5"
                            class="pa-a"
                        >
                            <Button
                                :to="`/goods/review/${goodsId}`"
                                class="w-100per"
                            >
                                查看更多
                            </Button>
                        </div>
                    </template>
                </tab-panel>-->
                <tab-panel
                    v-if="freightTemplateChargeDesc"
                    label="配送说明"
                    :sign="2"
                >
                    <div
                        v-if="+currentSign === 2"
                        class="pa-a bc-w"
                    >
                        <Widget
                            :json="freightTemplateChargeDesc"
                            no-show-complete
                        />
                    </div>
                </tab-panel>
                <tab-panel
                    v-if="aftersaleJson"
                    label="售后理赔"
                    :sign="3"
                >
                    <div
                        v-if="+currentSign === 3"
                        class="pa-a bc-w"
                    >
                        <Widget
                            :json="aftersaleJson"
                            no-show-complete
                        />
                    </div>
                </tab-panel>
            </tabs>
        </container>
        <div
            v-if="isPresellGoods"
            class="second-footer"
        >
            预计 {{ goods.estimateShippingTime | moment('YYYY年MM月DD日') }} 24点前发货
        </div>
        <div class="footer">
            <linker
                :to="isDistributor?`/distributor/home`:`/`"
                class="btn cart"
            >
                <div>
                    <i class="f-icon fs-more">&#Xf006;</i>
                    <div class="fs-small">
                        首页
                    </div>
                </div>
            </linker>
            <linker
                v-if="$hasPower(['cart:use'])"
                to="/cart"
                class="btn cart"
            >
                <div>
                    <em v-if="cartCount">{{ cartCount }}</em>
                    <i class="f-icon fs-more">&#Xf004;</i>
                    <div class="fs-small">
                        购物车
                    </div>
                </div>
            </linker>

            <div
                v-if="meiQiaAllSet"
                class="btn cart"
                @click="onService"
            >
                <div>
                    <i class="f-icon fs-more">&#xf024;</i>
                    <em v-if="unreadMsgCount">{{ unreadMsgCount }}</em>
                    <div class="fs-small">
                        客服
                    </div>
                </div>
            </div>
            <template v-if="isActivityGoods">
                <BuyPopup
                    class="btn btn-second"
                    :disabled="!isCanBuy"
                    :tips-msg="goods.buyLimitMsg"
                    :data="goods"
                >
                    <div>
                        {{ goods.price }}
                        <br>
                        单独购买
                    </div>
                </BuyPopup>
                <BuyPopup
                    ref="groupBuyPopup"
                    class="btn btn-main"
                    :disabled="!isAllowOrder"
                    order-type="20"
                    :data="activityGoods"
                >
                    发起 {{ activityGoodsDetail.groupBuyNums }} 拼团
                </BuyPopup>
            </template>
            <template v-else>
                <BuyPopup
                    v-if="!isPresellGoods && $hasPower(['cart:use'])"
                    class="btn btn-second"
                    type="cart"
                    :disabled="!isCanBuy"
                    :tips-msg="goods.buyLimitMsg"
                    :data="goods"
                />
                <BuyPopup
                    class="btn btn-main"
                    :disabled="!isCanBuy"
                    :tips-msg="goods.buyLimitMsg"
                    :data="goods"
                />
            </template>
        </div>

        <!-- 弹框 -->
        <XDialog ref="allGroupDialog">
            <div
                class="header tc-red"
                slot="header"
            >
                <div class="label">
                    正在拼团
                </div>
            </div>
            <div
                class="body npa-a"
                slot="body"
            >
                <List>
                    <GroupItem
                        v-for="item in allGroupBuyList"
                        :key="item.id"
                        :goods="goods"
                        :data="item"
                    />
                </List>
            </div>
        </XDialog>
        <!--微信分享-->
        <transition name="bounce">
            <div
                class="share-popup"
                v-show="sharePopupShow"
                @click="sharePopupShow=false"
                :style="`background: rgba(0, 0, 0, .8) url('${shareImg}') top center no-repeat; background-size:100% auto;`"
            />
        </transition>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import { Buyer } from '@/api';
import shareImg from '@/assets/images/share/share-earn.png';
import Clipboard from 'clipboard';
import meiQiaMixin from '@/mixin/meiQiaMixin';
import MeiQiaControl from '@/store/meiQia/MeiQiaControl';
import FulfilGiveBox from './_FulfilGiveBox';

export default {
    name: 'GoodsDetail',
    components: { FulfilGiveBox },
    mixins: [pageMixin, meiQiaMixin],
    data() {
        return {
            reviewList: null,
            reviewListTotalRows: 0,
            goods: {},
            address: {},
            sharedData: {},
            freightData: {},
            collectGoods: {},
            allGroupBuyList: [],
            freightTemplateChargeDesc: '',
            aftersaleJson: '',
            currentSign: 1,
            countdown: {},
            shareImg,
            sharePopupShow: false,
        };
    },
    beforeRouteEnter(to, from, next) {
        Buyer.Gd.Goods.goodsDetail({ id: to.params.id })
            .then(json => {
                const res = json.data.data;
                const {
                    goods,
                    freightTemplateChargeDesc,
                    aftersaleJson,
                    buyerDeliveryAddress: address,
                    collectGoods,
                } = res;
                next(vm => {
                    vm.goods = goods;
                    vm.freightTemplateChargeDesc = freightTemplateChargeDesc;
                    vm.aftersaleJson = aftersaleJson;
                    vm.collectGoods = collectGoods || {};
                    vm.address = address || {};
                    vm.setShareData({
                        title: goods.goodsName,
                        desc: goods.goodsSubtitle,
                        imgUrl: goods.mainImageUrl,
                        link: to.fullPath,
                        successData: {
                            urllinkType: 1,
                            objectId: goods.id,
                        },
                    }).then(data => {
                        vm.sharedData = data;
                    });
                    vm.getReviewList();
                });
            })
            .catch(() => {
                next();
            });
    },
    computed: {
        goodsId() {
            return this.$route.params.id;
        },
        isActivityGoods() {
            return this.goods.isActivityGoods;
        },
        activityGoods() {
            const { goods, isActivityGoods } = this;
            const obj = { ...goods };
            if (!isActivityGoods) return {};
            obj.maxPrice = obj.activityMaxPrice;
            obj.minPrice = obj.activityMinPrice;
            obj.price = obj.activityPrice;
            obj.showStock = obj.activityShowStock;
            obj.skuMap = obj.activitySkuMap;
            obj.sku = obj.activitySku;
            return obj;
        },
        activityGoodsDetail() {
            return this.goods.activityGoodsDetail || {};
        },
        imgList() {
            const {
                goods: { imgList, goodsVideoUrl },
            } = this;
            if (!goodsVideoUrl && (!imgList || !imgList.length)) return [];
            const videoList = [];
            if (goodsVideoUrl) {
                videoList.push({
                    isVideo: true,
                    src: goodsVideoUrl,
                    cover: (imgList || [])[0],
                });
            }
            const imageList = imgList.map(item => {
                return {
                    imgUrl: item,
                };
            });
            return videoList.concat(imageList);
        },
        freight() {
            const { freightData } = this;
            if (!this.address.id) return '';
            const mode = freightData.freightMode;
            const freight = freightData.freightAmount;
            // const freeFreightOrderAmount = this.address.freeFreightOrderAmount;
            switch (mode) {
                case 1:
                    return '全场包邮';
                case 2:
                case 3:
                    return !+freight ? '免运费' : +freight === -1 ? '无法配送' : freight;
                default:
                    return '';
            }
        },
        isCollect() {
            return this.collectGoods.id;
        },
        // 是否预售
        isPresellGoods() {
            return !!this.goods.isPresellGoods;
        },
        // 是否预售中
        isPreselling() {
            return this.isPresellGoods ? +this.countdown.status === 2 : true;
        },
        isAllowOrder() {
            return !!+this.moduleConfig.isAllowOrder && this.isPreselling;
        },
        isCanBuy() {
            return this.isAllowOrder && this.freightData.freightAmount !== -1 && this.goods.buyLimitType === 0;
        },
        unreadCount() {
            return this.unreadMsgCount;
        },
        fulfilGiveList() {
            return this.goods.fulfilGiveList || [];
        },
        // 销量
        goodsSales() {
            return this.goods.hasOwnProperty('goodsSales') ? this.goods.goodsSales + '' : '';
        },
    },
    watch: {
        address(val) {
            if (val && val.id) {
                this.$nprogress.start();
                this.$api.Buyer.Sp.ShopFreightTemplate.getGoodsFreightInfo({
                    goodsId: this.goods.id,
                    deliveryAddressId: val.id,
                }).then(json => {
                    this.$nprogress.done();
                    this.freightData = json.data.data;
                });
            }
        },
    },
    methods: {
        getReviewList() {
            // console.log(this.goods);
            this.$api.Buyer.Ex.GoodsReview.goodsReviewList({
                goodsId: this.goods.id,
                currentPage: 1,
                pageSize: 5,
            }).then(json => {
                // console.log(json.data.data);
                const res = json.data;
                this.reviewList = res.data;
                this.reviewListTotalRows = res.pagination.totalRows;
            });
        },
        onBuyTip() {
            if (this.goods.buyLimitType !== 0) {
                this.$messageBox.tips(this.goods.buyLimitMsg);
            }
        },
        onCollect() {
            if (!this.isCollect) {
                this.$api.Buyer.Mb.CollectGoods.collectGoods({
                    goodsId: this.goods.id,
                }).then(json => {
                    const res = json.data;
                    console.log(res);
                    this.collectGoods = res.data;
                    this.$messageBox.tips(res.msg, '温馨提示');
                });
            } else {
                this.$api.Buyer.Mb.CollectGoods.delCollectGoods({
                    ids: this.isCollect,
                }).then(json => {
                    const res = json.data;
                    console.log(res);
                    this.collectGoods = {};
                    this.$messageBox.tips(res.msg, '温馨提示');
                });
            }
        },
        onOpenGroup() {
            this.$refs['groupBuyPopup'].open();
        },
        onMoreGroup() {
            console.log(123);
            if (this.allGroupBuyList.length) {
                this.$refs['allGroupDialog'].open();
            } else {
                this.$api.Buyer.Ex.GroupBuy.groupBuyList({ goodsId: this.goodsId }).then(json => {
                    const res = json.data.data;
                    this.allGroupBuyList = res;
                    console.log(res);
                    this.$refs['allGroupDialog'].open();
                });
            }
        },
        onShareEarn() {
            this.$wx.shareLater({
                ...this.sharedData.successData,
                shareUuid: this.sharedData.uuid,
                urllink: this.sharedData.link,
                shareChannel: 3,
            });
            this.$router.push(`/distributor/goods/shareEarn/${this.goodsId}?shareUuid=${this.sharedData.uuid}`);
        },
        onTabChange(item) {
            console.log(item.sign);
            this.currentSign = item.sign;
        },
        initClipboard() {
            const { copyRecommend } = this.$refs;
            if (!copyRecommend) {
                setTimeout(() => {
                    this.initClipboard();
                }, 500);
                return;
            }
            // console.log(copyRecommend);
            const copySuccess = () => {
                this.$wx.shareLater({
                    ...this.sharedData.successData,
                    shareUuid: this.sharedData.uuid,
                    urllink: this.sharedData.link,
                    shareChannel: 3,
                });
                this.$messageBox.tips('分享链接复制成功', 3000);
            };
            const copyError = () => {
                this.$messageBox.tips('分享链接复制失败', 3000);
            };
            const $node = new Clipboard(copyRecommend.$el || copyRecommend);
            $node.on('success', copySuccess);
            $node.on('error', copyError);
        },
        onShareBtn() {
            if (!this.$globalVar.device.isWeiXin) return;
            this.sharePopupShow = true;
        },
        onService() {
            if (!this.$globalVar.userData.id) {
                this.$router.replace('/buyer/login');
                return;
            }
            MeiQiaControl.showPanel();
        },
    },
    mounted() {
        !this.$globalVar.device.isWeiXin && this.initClipboard();
    },
};
</script>

<style lang="scss" scoped>
.footer {
    background-color: #fff;

    > .cart {
        color: $gray6;
        flex: 0 0 auto;
        width: auto;
        position: relative;

        em {
            font-style: normal;
            font-size: 75%;
            position: absolute;
            top: 0;
            right: 0;
            background-color: $color-red;
            color: #fff;
            line-height: 1.5;
            min-width: 1.5em;
            text-align: center;
            border-radius: 100rem;
        }
    }
}

.second-footer {
    background-color: mix(#fff, $color-red, 80%);
    line-height: 2;
    color: $color-red;
    text-align: center;
    font-size: 80%;
    min-height: inherit;
}

.share-earn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding 0;
    background-color: #ffeff0;
    border: 0.05rem solid $color-red;
    border-radius: 0.1rem;

    + .share-earn {
        margin-top: $margin;
    }

    > .inner {
        flex: 1 1 auto;
        padding: 0 $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        border-right: 0.05rem dashed $color-red;
        font-weight: bold;

        &,
        .price {
            color: $color-red;
        }

        .commission-list {
            flex: 1 1 1%;

            > .badge {
                + .badge {
                    margin-top: $margin-small;
                }
            }

            > .dividing-line {
                border-bottom: 0.05rem dashed $color-red;
                height: 0;
                margin: $margin 0;
            }
        }
    }

    > .btn {
        margin: 0 $margin;
    }
}

.share-income {
    padding: $padding;

    > .inner {
        padding: $padding;
        background-color: #ffeff0;
        border-radius: 0.1rem;

        > .body {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-bottom: $padding-small;

            > .info {
                > .label {
                    color: $color-red;
                }

                > .value {
                    > .price {
                        color: $color-red;
                        font-size: 0.6rem;
                        font-weight: bold;
                        margin-right: $margin-small;
                    }
                }
            }

            > .share-btn {
                width: 1rem;
                height: 1rem;
                background-color: $color-red;
                color: #fff;
                border-radius: 100vw;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                line-height: 1;
            }
        }

        > .footer {
            background-color: transparent;
            padding-top: $padding-small;
            border-top: 1px dashed $color-red;
        }
    }
}
</style>

<style lang="scss">
.goods-swiper {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;

    > .swiper-container {
        position: absolute;
        z-index: 100;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    > .sold-out {
        position: absolute;
        z-index: 200;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
        background: rgba(0, 0, 0, 0.5) url('~@/assets/images/comm/icon02.png') center no-repeat;
        background-size: 70%;
        width: 100%;
        height: 100%;
    }
}

.goods-info {
    // padding: $padding 0;
    background-color: #fff;

    > .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;

        > .label,
        > .price-wrap,
        > .countdown-wrap {
            min-height: 1rem;
            padding: 0 $padding;
        }

        > .label {
            flex: 0.4 0.4 1%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background-color: $color-main;
            color: #fff;
            /*font-size: 120%;*/
        }

        > .price-wrap {
            flex: 0.54 0.54 1%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            background-color: $color-main;

            .price {
                color: #fff;
                line-height: 1.2;
            }

            > .price {
                font-size: 0.4rem;
            }

            > .old-price {
                color: #fff;
                font-size: 0.22rem;
                line-height: 1.2;
            }
        }

        > .countdown-wrap {
            flex: 1 1 1%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: $color-yellow;

            > .countdown {
                font-size: 0.3rem;
                justify-content: center;
                color: $color-red;
            }
        }
    }

    > .body {
        padding: $padding-small $padding;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        > .label-wrap {
            flex: 1 1 auto;

            > .name {
                font-size: 0.32rem;
            }

            > .desc {
                color: $gray6;
                line-height: 1.2;
                margin-top: $margin;
            }
        }

        > .share {
            flex: 0 0 auto;
            padding: $padding;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 0.22rem;
            color: $gray6;

            > .f-icon {
                font-size: 0.6rem;
                line-height: 1;
                margin-bottom: $margin-small;
            }

            > .label {
                white-space: nowrap;
            }

            &.active {
                color: $color-main;
            }
        }
    }

    > .footer {
        padding: $padding-small $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;

        .rise-tips {
            display: inline-block;
            padding: 0 $padding-small;
            margin-left: $margin-small;
            line-height: 1.6;
            border: 1px solid $color-border;
            border-radius: 5px;
            color: $color-text-minor;
        }
    }
}
</style>
