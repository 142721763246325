<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page
        class="bc-w"
        :title="pageData.title"
    >
        <container ref="container">
            <template v-if="groupBuyDetail.groupBuyStatus===10">
                <template v-if="isJoinGroup">
                    <Invite
                        class="ma-bor-b"
                        :data="groupBuyData"
                    />
                    <JoinUser
                        class="ma-bor-b"
                        :data="groupBuyDetail"
                    />
                </template>
                <!-- 未参与 -->
                <GroupInfo
                    v-else
                    class="ma-bor-b"
                    :data="groupBuyData"
                />
            </template>
            <template v-else>
                <GroupStatus
                    :join-group="isJoinGroup"
                    :status="groupBuyDetail.groupBuyStatus"
                />

                <GroupInfo
                    class="ma-bor-b"
                    :data="groupBuyData"
                />
            </template>

            <List class="ma-bor-b">
                <GroupGoodsItem :data="goods" />
            </List>
        </container>
    </Page>
</template>

<script>
import { Buyer } from '@/api';
import pageMixin from '@/mixin/layout/page';

export default {
    name: 'GroupBuy',
    mixins: [pageMixin],
    data() {
        return {
            groupBuyData: {},
        };
    },
    beforeRouteEnter(to, from, next) {
        Buyer.Ex.GroupBuy.shareLinkGroupBuyDetail({ orderId: to.params.orderId }).then(json => {
            const res = json.data.data;
            const { isJoinGroup, isPay, groupBuyDetail } = res;
            if (isJoinGroup && !isPay) {
                next('/order/list/0');
                return;
            } else if (isJoinGroup && groupBuyDetail.groupBuyStatus === 100) {
                next(`/order/detail/${to.params.orderId}`);
                return;
            }
            next(vm => {
                vm.groupBuyData = res;
                const { goods } = res;
                vm.shareData = {
                    title: goods.goodsName,
                    desc: goods.goodsSubtitle,
                    imgUrl: goods.mainImageUrl,
                    link: to.path,
                    successData: {
                        urllinkType: 4,
                    },
                };
            });
        });
    },
    computed: {
        orderId() {
            return this.$route.params.orderId;
        },
        isGroupBuyAvailable() {
            return this.groupBuyData.groupBuyAvailable;
        },
        isJoinGroup() {
            return this.groupBuyData.isJoinGroup;
        },
        isPay() {
            return this.groupBuyData.isPay;
        },
        goods() {
            // this.$utils.Comm.makeDataMap(this.groupBuyData.goods || {}, this.$utils.DataMap.goods);
            const obj = this.groupBuyData.goods || {};
            obj.groupBuyNums = this.groupBuyDetail.groupBuyNums;
            return obj;
        },
        groupBuyDetail() {
            return this.groupBuyData.groupBuyDetail || {};
        },
    },
};
</script>

<style lang="scss">
</style>
