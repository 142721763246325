<!-- Created by henian.xu on 2019/2/25. -->

<template>
    <Page>
        <container ref="container">
            <ReviewItem
                v-for="item in reviewList"
                :key="item.id"
                :data="item"
            />

            <infinite-loading
                ref="infinite"
                :disabled="infiniteDisabled"
                @infinite="onInfinite"
            />
        </container>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import infiniteLoading from '@/mixin/infiniteLoading';
export default {
    mixins: [pageMixin, infiniteLoading],
    name: 'Review',
    data() {
        return {
            reviewList: [],
        };
    },
    computed: {
        goodsId() {
            return this.$route.params.goodsId;
        },
    },
    methods: {
        getListData({ goodsId = this.goodsId, currentPage, pageSize } = {}) {
            return this.$api.Buyer.Ex.GoodsReview.goodsReviewList({
                goodsId,
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
                pageSize,
            }).then(json => {
                const res = json.data;
                this.reviewList = this.reviewList.concat(res.data);
                return Promise.resolve(json);
            });
        },
    },
};
</script>

<style lang="scss">
</style>
